<!-- @format -->
<script lang="ts" context="module">

  type DateFormat = "date" | "weekday" | undefined;
  type MobileLayout = "one/row" | "two/row";

  export type CampaignConfig = {
    date_format: DateFormat;
    description_text_color: string;
    filter_by_tag: number;
    filter_by_day: string | undefined;
    filter_by_store?: number;
    filter_by_wishlist?: number[];
    mobile_offer_layout: MobileLayout;
    wish_list_enabled: boolean;
    venue_name: Readable<string>;
    locale: WritableAtom<string>;
    hide_default_text?: boolean;
  };
</script>

<script lang="ts">
  import { getStoresContext, type StoresKey } from "~/stores";
  import CampaignOfferItem from "./CampaignOfferItem.svelte";
  import { filterOffers } from "./utils";
  import { filter_by_category, filter_by_day, filter_by_store, user_likes } from "./store";
  import { getFragmentConfig, getFragmentConfigOr } from "../basic_component/util";
  import type { ManageAPI } from "~/manage-api/types";
  import { setContext } from "svelte";
  import { type Readable, derived } from "svelte/store";
  import { type WritableAtom } from "nanostores";

  export let request_store_key: StoresKey;
  export let fragment: Fragment;
  
  let wish_list_enabled = getFragmentConfigOr("wishList", fragment, false); // wishlist button enabled/ disabled from the fragment
  let wish_list_toggle = false // when user clicks on the wish list button to see their liked offers in the UI

  const stores_context = getStoresContext(request_store_key);
  const { T, offers_ext, locale, site } = stores_context;
  const config: CampaignConfig = {
    date_format: getFragmentConfig("validDateFormat", fragment),
    description_text_color: getFragmentConfigOr("descriptionTextColor", fragment, '#000'),
    filter_by_tag: getFragmentConfig("filterByCategoryID", fragment) || 0,
    filter_by_day: getFragmentConfig("filterByDay", fragment),
    locale,
    mobile_offer_layout: getFragmentConfig("offerMobile", fragment) || "one/row",
    wish_list_enabled,
    venue_name: derived(site, (site, set) => set(site?.shoppingCenterName || '')),
    filter_by_wishlist: wish_list_toggle ? $user_likes : undefined,
    hide_default_text: getFragmentConfigOr("hideDefaultText", fragment, false)
  };

  setContext("config", config);

  let items: ManageAPI.OfferExt[] = [];
  let filter_items: ManageAPI.OfferExt[] = [];

  $: t = $T;

  $: if ($offers_ext?.items) {
    items = filter_items = filterOffers($offers_ext.items, config, true);
  }

  $: if (items.length || wish_list_toggle) {
    filter_items = filterOffers(items, {
      filter_by_day: $filter_by_day,
      filter_by_tag: $filter_by_category,
      filter_by_store: $filter_by_store,
      filter_by_wishlist: wish_list_toggle ? $user_likes : undefined,
    });
  }

  function toggleWishList() {
    wish_list_toggle = !wish_list_toggle;
  }

  let scrollingPosition = 0;
  let elm: HTMLElement;
</script>

<svelte:window bind:scrollY={scrollingPosition} />

<div class="container" bind:this={elm}>
  {#if items.length && config.wish_list_enabled}
    <div class="row">
      <div class="col-12 text-center">
        <button class="wishlist__btn" on:click={toggleWishList}
          >{wish_list_toggle ? t`offers.allOffers` : t`offers.showWishList`}</button>
      </div>
    </div>
  {/if}
  <div class="row pt-4">
    {#if filter_items.length}
      {#each filter_items as offer (offer.id)}
        <CampaignOfferItem {offer} {request_store_key} />    
      {/each}
    {:else if !config.hide_default_text}
      <div class="col-12 text-center">
        <p>{t`offers.noOffers`}</p>
      </div>
    {/if}
  </div>
</div>
{#if scrollingPosition > 800}
  <button class="back-to-top" on:click={() => elm.scrollIntoView()}>
    <i class="fas fa-angle-double-up"></i>
  </button>
{/if}

<style lang="scss">
  .back-to-top {
    position: fixed;
    bottom: 25px;
    right: 25px;
    font-size: 20px;
    border-radius: 35px;
    background-color: var(--brandColor2);
    border: 3px solid var(--brandColor1);
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--brandColor1);
    padding: 12px 20px;
    z-index: 2;
    width: 60px;
    height: 60px;
    cursor: pointer;

    @media screen and (max-width: 1400px) {
      bottom: 15px;
      right: 15px;
    }
    @media screen and (max-width: 767px) {
      bottom: 5px;
      right: 5px;
      padding: 8px 16px;
      width: 40px;
      height: 40px;
    }
  }
  .wishlist__btn {
    padding: 10px;
    margin: 7px;
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
    cursor: pointer;
    background-color: var(--brandColor1);
    border: 3px solid var(--brandColor2);
    color: var(--brandColor2);
  }
</style>
