<!-- @format -->
<script lang="ts">
  import { getStoresContext } from "~/stores";
  import type { ManageAPI } from "~/manage-api/types";
  import type { StoresKey } from "~/stores";
  import OfferPeriod from "./OfferPeriod.svelte";
  import { slide } from "svelte/transition";
  import { getContext } from "svelte";
  import type { CampaignConfig } from "./CampaignItemListing.svelte";
  import { user_likes } from "./store";

  export let offer: ManageAPI.OfferExt;
  export let request_store_key: StoresKey;

  const T = getStoresContext(request_store_key).T;

  const config: CampaignConfig = getContext('config');
  const venue_name = config.venue_name;

  let open = false;
  let is_liked = user_likes.isLiked(offer.id);

  function toggleLike () {
    is_liked = user_likes.toggle(offer.id);
  }

  $: t = $T;
  $: publisher_name = offer.publisher == 'venue' ? $venue_name : offer.store.name;
</script>

<div
  class="col-md-6 col-lg-4 offers__card ng-scope"
  class:col-12={config.mobile_offer_layout == "one/row"}
  class:col-6={config.mobile_offer_layout == "two/row"}
  class:offers__card-sm={config.mobile_offer_layout == "two/row"}>
  <div class="offers__link">
    <div class="offers__image-wrapper">
      <div class="offers__image">
        <picture>
          {#if offer.highResolutionImage}
            <source srcset={offer.highResolutionImage} media="(min-width: 600px)" />
          {/if}
          {#if offer.thumbnail}
            <img src={offer.thumbnail} alt={offer.title} />
          {:else if offer.store?.logoURL}
            <img src={offer.store.logoURL} alt={offer.store.name} />
          {/if}
        </picture>
      </div>
      {#if config.wish_list_enabled}
        <button
          class="offers__heart"
          on:click={toggleLike}>
          <i class="fa-heart far" class:fa={is_liked}></i>
        </button>
      {/if}
      <OfferPeriod {offer} />
      <p class="offers__shop_link">{publisher_name}</p>
    </div>
  </div>
  <div class="offers__info">
    <p class="offers__title">{offer.title}</p>
    <p class="offers__description-1">{offer.description1}</p>
    <p class="offers__description-2">{offer.description2}</p>
    {#if offer.lowestPriceInXDays}
      <p class="omnibus-statement">
        {t`offers.daysLowestPrice`}: {offer.lowestPriceInXDays}
      </p>
    {/if}
    {#if offer.longDescription}
      <button
        class="offers__show-more"
        style="color: {config.description_text_color};"
        on:click={() => (open = !open)}>
        {t`offers.openInfo`}
        <i class="fas fa-chevron-down" class:fa-chevron-up={open}></i>
      </button>
      {#if open}
        <div class="offers__expandable" transition:slide>
          <p class="offers__long-description" style="color: {config.description_text_color};">
            {offer.longDescription}
          </p>
        </div>
      {/if}
    {/if}
  </div>
</div>

<style lang="scss">
  .offers__image-wrapper {
    width: 100%;
    display: block;
    position: relative;
    overflow: hidden;
    padding-bottom: 40px;
  }

  .offers__image img {
    position: absolute;
    max-width: 100%;
    width: auto !important;
    height: auto;
    max-height: 100%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    background-color: #fff;
  }

  .offers__image {
    width: 100%;
    padding-top: 100%;
    position: relative;
    overflow: hidden;
    background-color: #fff;
  }

  .offers__card {
    --_card-body-text-color: var(--card-body-text-color, var(--brandColor2));
    margin: 15px;
    max-width: calc(33.333333% - 30px);
    padding: 0 5px;
    //box-shadow: 0 0 2px 0 rgba(0,0,0,0.12), 0 2px 4px 0 rgba(0,0,0,0.22);
    @media screen and (max-width: 991px) {
      max-width: calc(50% - 30px);
    }
    @media screen and (max-width: 767px) {
      margin: 15px 10px;
      max-width: calc(100% - 20px);
    }
  }

  .offers__link {
    color: #000;
    display: block;
    text-decoration: none;
    position: relative;
    word-wrap: break-word;
    font-size: 18px;
    z-index: 2;
    padding: 0 25px;
  }

  .offers__heart {
    position: absolute;
    top: 0;
    right: 5px;
    font-size: 22px;
    height: 32px;
    border: none;
    background-color: var(--heart-bg-color, #fff);
    color: var(--heart-color, #BF1A6a);
    i {
      padding: 5px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    i:hover {
      -moz-transform: scale(1.05);
      -webkit-transform: scale(1.05);
      transform: scale(1.05);
      transition-duration: 0.5s;
    }
  }

  .offers__info {
    position: relative;
    z-index: 1;
    margin-top: -75px;
    background-color: #787878;
    padding: 65px 20px 20px 20px;
    background-color: var(--brandColor1);
  }

  .offers__title {
    text-align: center;
    font-size: 17px;
    line-height: 24px;
    word-wrap: break-word;
    margin-bottom: 5px;
    color: var(--brandColor2);
  }

  .offers__description-1 {
    text-align: center;
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 0px;
    color: var(--brandColor2);
  }

  .offers__description-2 {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
    color: var(--brandColor2);
  }

  .omnibus-statement {
    margin-bottom: 1em;
    color: var(--brandColor2);
    font-size: 14px;
    font-style: italic;
    text-align: center;
  }

  .offers__shop_link {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;
    padding: 6px;
    background-color: var(--brandColor2);
    color: var(--brandColor1);
    text-align: center;
    display: inline-block;
    width: 100%;
    max-width: 200px;
    left: 50%;
    transform: translateX(-50%);
    bottom: 25px;
    position: absolute;
    margin: 0;
  }

  .offers__show-more {
    display: block;
    border: none;
    background: none;
    margin: auto;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 5px;
    cursor: pointer;

    .fas {
      transition-duration: 0.3s;
      margin-left: 4px;
    }
  }

  .offers__long-description {
    font-size: 16px;
    margin: 0;
    word-wrap: break-word;
    color: var(--_card-body-text-color);
  }

  /*
  Two offers in a row
 */
  @media screen and (max-width: 767px) {
    .offers__card.offers__card-sm {
      margin: 15px 8px;
      max-width: calc(50% - 16px);
    }
    .offers__card-sm {
      .offers__link {
        padding: 0 15px;
      }

      .offers__info {
        margin-top: -65px;
        padding: 47px 15px 15px 15px;
      }

      .offers__shop_link,
      .offers__show-more,
      .offers__long-description {
        font-size: 14px;
      }

      .offers__title {
        font-size: 18px;
      }

      .offers__description-1 {
        font-size: 35px;
      }

      .offers__description-2 {
        font-size: 16px;
      }
      .omnibus-statement {
        font-size: 14px;
      }
    }
  }

  @media screen and (max-width: 576px) {
    .offers__card.offers__card-sm {
      margin: 10px 2px;
      max-width: calc(50% - 4px);
    }
    .offers__card-sm {
      .offers__shop_link {
        max-width: 32vw;
      }

      .offers__shop_link,
      .offers__show-more,
      .offers__long-description {
        font-size: 13px;
      }

      .offers__title {
        font-size: 3.2vw;
        margin-bottom: 0px;
      }

      .offers__description-1 {
        font-size: 34px;
      }

      .offers__description-2 {
        font-size: 15px;
      }
      .omnibus-statement {
        font-size: 13px;
      }
    }
  }

  @media screen and (max-width: 450px) {
    .offers__card-sm {
      .offers__link {
        padding: 0 10px;
      }

      .offers__shop_link {
        line-height: 14px;
      }

      .offers__info {
        margin-top: -60px;
        padding: 40px 10px 10px 10px;
      }

      .offers__heart {
        font-size: 18px;
        height: 28px;
      }

      .offers__shop_link,
      .offers__show-more,
      .offers__long-description {
        font-size: 12px;
      }

      .offers__title {
        font-size: 3.5vw;
      }

      .offers__description-1 {
        font-size: 7.7vw;
      }

      .offers__description-2 {
        font-size: 13.5px;
      }
      .omnibus-statement {
        font-size: 11px;
      }
    }
  }
</style>
