<!-- @format -->
<script lang="ts">
  import type { ManageAPI } from "~/manage-api/types";
  import { convertToDateFromString, parseDayFromString } from "./utils";
  import type { CampaignConfig } from "./CampaignItemListing.svelte";
  import { getContext } from "svelte";

  export let offer: ManageAPI.Offer;
  
  const config: CampaignConfig = getContext('config');
  const { locale } = config;
  const {validityStartDate , endDate} = offer
</script>

{#if $locale && config.date_format == "date"}
  {#if validityStartDate != endDate}
    <p class="offers__validate">
      {convertToDateFromString(validityStartDate, $locale)}-{convertToDateFromString(endDate, $locale)}
    </p>
  {:else}
    <p class="offers__validate">
      {convertToDateFromString(validityStartDate, $locale)}
    </p>
  {/if}
{:else if $locale && config.date_format == "weekday"}
  {#if validityStartDate != endDate}
    <p class="offers__validate">
      {parseDayFromString(validityStartDate,$locale)}-{parseDayFromString(endDate, $locale)}
    </p>
  {:else}
    <p class="offers__validate">
      {parseDayFromString(validityStartDate, $locale)}
    </p>
  {/if}
{/if}

<style lang="scss">
  .offers__validate {
    display: inline-block;
    padding: 2px 10px;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    position: absolute;
    top: 0;
    left: 0;
    color: var(--brandColor2);
    background-color: var(--brandColor1);
  }
</style>
